import React from "react"
import PropTypes from "prop-types"

const RoundCheckBox = ({
  text,
  handleCheck,
  check,
  isSome,
  typeClass,
  isFront,
}) => {
  return (
    <div
      className="round-checkbox flex items-center justify-between"
    >
      <div
        className={isFront ? "text-sub" : "text"}
        style={{ order: 0}}
      >
        {text.charAt(0).toUpperCase() + text.slice(1)}
      </div>
      <div className="round relative flex items-center">
        <input
          type="checkbox"
          checked={check}
          id={text}
          onChange={handleCheck}
          className={typeClass}
        />
        <label htmlFor={text} className={isSome ? "some" : ""}></label>
      </div>
    </div>
  )
}

RoundCheckBox.propTypes = {
  text: PropTypes.string,
  handleCheck: PropTypes.func,
  check: PropTypes.bool,
  isSome: PropTypes.bool,
  typeClass: PropTypes.string,
  isFront: PropTypes.bool,
}

export default RoundCheckBox
