import React, { useState, useEffect } from 'react';
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import useDarkMode from "use-dark-mode"
import uploadImg from "~/images/icons8-upload-50.png"

const FooterBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  justify-content: space-around;

  .download {
    position: relative;
    width: 50%;
    height: 64px;
    background-color: #fff;
    Float: left;
  }

  .downloadDark {
    position: relative;
    width: 50%;
    height: 64px;
    background-color: #2E2E2E;
    Float: left;
  }

  .addtoHome {
    position: relative;
    width: 100%;
    height: 74px;
    color: black;
    margin-bottom: 20px;
    background-color: #ffffff;

    > div:first-child {
      display: flex;
    }

    .logo {
      width: 100%;
      max-height: 40px;
      max-width: 40px;
      margin-left: 5px;
      align-self: center;
    }

    .text {
      margin-left: 10px;
      margin-top: 16px;
      font-size: 14px;
    }

    .iconUpload {
      width: 20px;
      margin: 5px;
      position: relative;
      display: initial;
    }

    .iconClose {
      color: #2f2e3f;

      @media screen and (min-width: 413px) {
        position: fixed;
        transform: scale(1.2) translate(326px, 1px);
      }
    }
  }

  .addtoHome::after {
    position: absolute;
    content: '';
    height: 0px;
    width: 0px;
    left: 44.5%;
    bottom: -19px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #ffffff;
    z-index: 9999;
  }

  .rightplay {
    position: relative;
    width: 50%;
    height: 64px;
    background-color: #13c750;
    Float: right;
  }

  .bgIcon {
    width: 41px;
    height: 36px;
    background-color: #13c750;
    border-radius: 0.25rem;
    margin-left: 2px;
    margin-right: 4px;
  }

  @media screen and (max-width: 1024px) {
    .btnLoad {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: black;
    }

    .btnLoadDark {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: white;
    }

    .btnPlay {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 18px;
      cursor: pointer;
    }
  }
`

const PlayButton = styled.a`
  padding: 10px 60px 10px 60px;
  line-height: 1;
  transform: translateY(-10px);

  span {
    width: 36px;
    height: 36px;
    background: #fff;
    border-radius: 9px;

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      /* border-left: 20px solid #13b54a; */
      border-left: 20px solid ${props => props.backgroundColor};
      border-bottom: 10px solid transparent;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 8px 1px 8px 40px;
    transform: translate(10px, 0px);
  }
`

const Footer = ({ playUrl, langKey, themeColor, lobbyUrl }) => {
  const [isDarkMode, setDarkMode] = useState(false)
  const { value } = useDarkMode(isDarkMode)
  useEffect(() => {
    if (value !== isDarkMode) {
      setDarkMode(value)
    }
  })
  const data = useStaticQuery(graphql`
  query {
    allMarkdownRemark(filter: {collection: {eq: "common"}}) {
      edges {
        node {
          file
          frontmatter {
            navdownload
            playbutton
            download_iosurl
            download_androidurl
          }
        }
      }
    }
  }
`)
  const {
    node: { frontmatter },
  } = data.allMarkdownRemark.edges
    .find(item => item.node.file
      .split(".")[1]
      .replace("/", "") === langKey)
  const menuListPath = [
    { path: "/download", icon: "downloadWhite", textVal: "navdownload", },
  ]
  const [userData, setUserdata] = useState('')
  const [isAndroid, setIsAndroid] = useState(false)
  const [isShowBar, setShowBar] = useState(true)
  const handleBar = (val) => {
    setShowBar(val)
  }

  useEffect(() => {
    if (JSON.parse(window.localStorage.getItem('userData'))) {
      setUserdata(JSON.parse(window.localStorage.getItem('userData')))
    }
    const checkAndroid =
      /Android/i.test(
        navigator.userAgent
      )
    setIsAndroid(checkAndroid)
  }, [])

  return (
    <FooterBar className="lg:hidden">
      <div style={{ display: isAndroid || !isShowBar ? "block" : "none" }}>
        <div className={isDarkMode ? "downloadDark" : "download"}
          style={{backgroundColor: `${isDarkMode ? themeColor.dark_theme.background_color.primary : themeColor.light_theme.background_color.primary }`}}
        >
          <div className={isDarkMode ? "btnLoadDark" : "btnLoad"}>
            {menuListPath.map(item => (
              <a className="flex items-center"
                key={item.icon}
                href={`${isAndroid ? frontmatter.download_androidurl : frontmatter.download_iosurl}`}>
                <div className="bgIcon">
                  <i className={`icon ${item.icon}`}></i>
                </div>
                {frontmatter[item.textVal]}
              </a>
            ))
            }
          </div>
        </div>
        <div className="rightplay" style={{ background: themeColor.light_theme.background_color.third }}>
          <div className="btnPlay">
            <PlayButton
              backgroundColor={themeColor.light_theme.background_color.third}
              className="relative font-bold text-center text-white rounded"
              href={userData ? `${playUrl}${userData.token}` : lobbyUrl}
              target="_blank"
            >
              <span className="absolute top-0 left-0 flex items-center justify-center rounded"></span>
              {frontmatter.playbutton}!
            </PlayButton>
          </div>
        </div>
      </div>
      <div className="addtoHome" style={{ display: !isAndroid && isShowBar ? "flex" : "none" }}>
        <div>
          <img className="logo" src={'/icons/icon-512x512.png'} alt="" />
        </div>
        <div className="text">
          <span > Add Application </span>
          <br />
          <span> Tap on
            <img className="iconUpload"
              src={uploadImg}
            /> and select
          </span>
          <span> Add to Home screen </span>
        </div>
        <a className="iconClose" onClick={() => handleBar(false)}>&times;</a>
      </div>
    </FooterBar>
  )
}

export default Footer