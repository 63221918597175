import React, { useState, useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import Toggle from "~/components/dark-mode"
import styled, { css } from "styled-components"
import flagEN from "~/images/flag_en.png"
import flagTH from "~/images/flag_th.png"
// import questionIcon from "~/images/icon_question.png"
import useDarkMode from "use-dark-mode"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Squash } from "hamburger-react"
import GlobalStyle from "~/styles/js/global"

const PlayButton = styled.a`
  padding: 8px 40px 8px 60px;
  /* background: #13c750; */
  background: ${props => props.backgroundColor};
  line-height: 1;

  span {
    width: 33px;
    height: 100%;
    /* background: #13b54a; */
    background: ${props => props.backgroundColor};

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-top: 9px solid transparent;
      border-left: 13px solid #fff;
      border-bottom: 9px solid transparent;
    }
  }

  @media screen and (max-width: 1023px) {
    padding: 8px 40px 8px 180px;
  }
`
const LogoutButton = styled.a`
  padding: 10px 40px 10px 50px;
  background: #F4D03F;
  line-height: 1;

  span {
    width: 33px;
    height: 100%;
    background: #F1C40F;
  }

  @media screen and (max-width: 1320px) {
    padding: 30px 10px 8px 10px;
    margin-right: 10px;

    .textLogout {
      display: none;
    }
  }
`

const LogoutButtonMobile = styled.a`
  padding: 10px 40px 10px 125px;
  background: #F4D03F;
  line-height: 1;

  span {
    width: 33px;
    height: 100%;
    background: #F1C40F;
  }

  .textLogoutMobile {
    transform: translateX(-35px);
  }
`

const ModeWrapper = styled.div`
  span {
    margin-right: 8px;
  }
`

const LangButton = styled.div`
  color: #161616 !important;
  width: 58px;
  height: 35px;
  background-color: #f3f3f3;
  border-radius: 6px;
  backdrop-filter: blur(30px);
  font-size: 14px;
  padding-left: 20px;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
`

const SupportButton = styled.div`
  width: 24px;
  height: 24px;
  /* background: url(${props => props.questionIcon}) no-repeat center; */
  background: ${props => props.background};
  cursor: pointer;
  mask: url(${props => props.questionIcon}) no-repeat center / contain;
  -webkit-mask: url(${props => props.questionIcon}) no-repeat center / contain;
`

const SideMenu = styled.div`
  position: fixed;
  width: 286px;
  height: 100vh;
  left: 0;
  color: white;
  overflow-y: scroll;
  height: calc(100vh - 60px);

  .textPlay {
    position: absolute;
    transform: translate(90px, 0px);
    cursor: pointer;
  }

  .textmenu {
    position: absolute;
    transform: translate(50px, 0px);
  }

  .textsupportlight {
    position: absolute;
    transform: translate(70px, 0px);
    /* color: rgba(55, 65, 81, var(--tw-text-opacity)); */
  }

  .textsupportdark {
    position: absolute;
    transform: translate(70px, 0px);
    /* color: white; */
  }

  @media only screen and (orientation: landscape) {
    overflow-y: scroll;
    height: calc(100vh - 60px);
  }

  @supports (-webkit-touch-callout: none) {
      .textPlay {
      position: absolute;
      transform: translate(90px, -22px);
      cursor: pointer;
    }
  }
`

const MenuIcon = styled.div`
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: ${props => props.backgroundColor} 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  cursor: pointer;
  position: relative;

  .hamburger-react {
    position: absolute;
    transform: translate(-4.5px, -4px);
  }
`

const WebLogo = styled.img`
  width: ${props => props.width};
  height: ${props => props.height};
`

const IconNav = styled.div`
  background: ${props => props.background};
  mask: url(${props => props.icon}) no-repeat center / contain;
  -webkit-mask: url(${props => props.icon}) no-repeat center / contain;
  width: ${props => props.width};
  height: ${props => props.height};
  margin-right: 3px;
`

const Header = ({ playUrl, langKey, langsMenu, handleSupport, theme, iconWeb, lobbyUrl }) => {
  const [isSidebar, isShowSidebar] = useState(false)
  const [isDarkMode, setDarkMode] = useState(false)
  const { value } = useDarkMode(isDarkMode)

  useEffect(() => {
    if (value !== isDarkMode) {
      setDarkMode(value)
    }
  })
  const toggleSidebar = () => {
    isSidebar ? isShowSidebar(false) : isShowSidebar(true)
  }
  const toggleSideandSupport = (val) => {
    toggleSidebar(val)
    handleSupport(val)
  }
  const logoutBtn = () => {
    localStorage.clear();
    window.location.reload();
  }
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: {collection: {eq: "common"}}) {
        edges {
          node {
            file
            frontmatter {
              navdownload
              navevent
              navgames
              navmode
              playbutton
              logoutbutton
              contact_title
              logo {
                height
                web_logo
                width
              }
            }
          }
        }
      }
      allMarkdownPage: allMarkdownRemark(filter: {fields: {langKey: {ne: null}}}, limit: 1000) {
        edges {
          node {
            fields {
              slug
              langKey
              }
              frontmatter {
                templateKey
                enable_menu
                icon_menu
                icon_width
                icon_heigh
              }
            }
          }
        }
    }
  `)
  const {
    node: { frontmatter },
  } = data.allMarkdownRemark.edges
    .find(item => item.node.file
      .split(".")[1]
      .replace("/", "") === langKey)

  const menuListPath = [
    { path: "/", key: "index", icon: "games", textVal: "navgames", },
    { path: "/event", key: "event", icon: "event", textVal: "navevent", },
    { path: "/download", key: "download", icon: "download", textVal: "navdownload", },
  ]
  const [userData, setUserdata] = useState('')
  const menuList = data.allMarkdownPage.edges
    .filter(items => items.node.fields.langKey === langKey && items.node.frontmatter.enable_menu)
    .sort((a, b) => b.node.frontmatter.templateKey.localeCompare(a === menuListPath.keys));
  useEffect(() => {
    if (JSON.parse(window.localStorage.getItem('userData'))) {
      setUserdata(JSON.parse(window.localStorage.getItem('userData')))
    }
  }, [])

  return (
    <header className="fixed z-10 w-full">
      <GlobalStyle />
      <div
        className="flex items-center justify-between w-full px-10"
        style={{ backgroundColor: frontmatter.navbar }}
      >
        <div className="flex items-center">
          <div className="lg:hidden" onClick={() => toggleSidebar(isSidebar)}>
            <MenuIcon backgroundColor={theme.light_theme.background_color.third}>
              <Squash toggled={isSidebar} toggle={isShowSidebar} size={25} color="#ffffff" />
            </MenuIcon>
          </div>
          {menuList.map(item => (
            <Link className="flex items-center font-bold nav-link md:hidden"
              key={item.node.frontmatter.templateKey}
              to={item.node.fields.slug}>
              <IconNav icon={`/${item.node.frontmatter.icon_menu}`}
                width={`${item.node.frontmatter.icon_width}px`}
                height={`${item.node.frontmatter.icon_heigh}px`}
                background={isDarkMode ? theme.dark_theme.background_color.fourth : theme.light_theme.background_color.fourth}
              />
              {frontmatter[menuListPath.find(items => items.key === item.node.frontmatter.templateKey).textVal]}
            </Link>
          ))}
        </div>
        <Link
          className="absolute ml-0 text-lg transform left-1/2 -translate-x-2/4"
          to={`${langKey === "en" ? "/" : `/${langKey}/`}`}
        >
          <WebLogo src={`/${frontmatter.logo.web_logo}`}
            width={`${frontmatter.logo.width}px`}
            height={`${frontmatter.logo.height}px`}
            alt="" />
        </Link>
        <div className="flex items-center">
          <div className="mr-5 md:hidden">
            <ModeWrapper className="flex items-center">
              <span style={{ color: `${isDarkMode ? theme.dark_theme.font_color.primary : theme.light_theme.font_color.primary}` }}>{frontmatter.navmode}:</span>
              <Toggle />
            </ModeWrapper>
          </div>
          <div className="mr-5 md:hidden">
            <PlayButton
              className="relative font-bold text-white rounded"
              href={userData ? `${playUrl}${userData.token}` : lobbyUrl}
              target="_blank"
              backgroundColor={isDarkMode ? theme.dark_theme.background_color.third : theme.light_theme.background_color.third}
            >
              <span className="absolute top-0 left-0 flex items-center justify-center rounded"></span>
              {frontmatter.playbutton}
            </PlayButton>
          </div>
          {userData ?
            <div onClick={() => logoutBtn()} className="flex mr-5 cursor-pointer md:hidden">
              <LogoutButton
                className="relative font-bold text-white rounded">
                <span className="absolute top-0 left-0 flex items-center justify-center bg-green-400 rounded">
                  <FontAwesomeIcon className="justify-items-start" icon="sign-out-alt" />
                </span>
                <div className="textLogout">
                  {frontmatter.logoutbutton}
                </div>
              </LogoutButton>
            </div> : ''}
          <div className="mr-5 md:mr-0">
            <Link to={langsMenu.find(item => !item.selected).link}>
              <LangButton
                className="flex items-center justify-center"
                style={{
                  backgroundImage: `url(${langKey === "th" ? flagTH : flagEN})`,
                  backgroundSize: "20px",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "8px center",
                }}
              >
                {langKey.toUpperCase()}
              </LangButton>
            </Link>
          </div>
          <div>
            <SupportButton className="flex md:hidden"
              onClick={() => handleSupport(true)}
              questionIcon={`/${iconWeb.question}`}
              background={isDarkMode ? theme.dark_theme.background_color.fourth : theme.light_theme.background_color.fourth}
            ></SupportButton>
          </div>
        </div>
      </div>
      <SideMenu className={isSidebar ? "fadeIn lg:hidden" : "fadeOut lg:hidden"}>
        {userData ?
          <div className="flex items-center m-5">
            <img className="w-24" src={`/${frontmatter.web_logo}`} alt="" />
            <div className="space-y-2">
              <span className="block" style={{ color: "#13C750" }}>Welcome</span>
              <span className={isDarkMode ? "block text-white" : "block text-black"}>{`${userData.user.username}`}</span>
              <span className={isDarkMode ? "block text-white bg-gray-600 rounded-md p-1" : "block text-black bg-white rounded-md p-1"}>
                {`${userData.user.wallet.balance.toFixed(2)}`} {`${userData.user.currency}`}
              </span>
            </div>
          </div>
          : <div className="flex items-center m-5">
            <img className="w-24" src={`/${frontmatter.web_logo}`} alt="" />
            <div className="space-y-2">
              <span className="block text-lg" style={{ color: theme.light_theme.font_color.third }}>Welcome</span>
            </div>
          </div>
        }
        <div className="m-8">
          <PlayButton
            backgroundColor={theme.light_theme.background_color.third}
            className="relative font-bold text-center text-white rounded"
            href={userData ? `${playUrl}${userData.token}` : lobbyUrl}
            target="_blank"
          >
            <span className="absolute top-0 left-0 flex items-center justify-center rounded"></span>
          </PlayButton>
          <div className="textPlay">{frontmatter.playbutton}</div>
        </div>
        {userData ?
          <div onClick={() => logoutBtn()} className="flex m-8 cursor-pointer">
            <LogoutButtonMobile
              className="relative font-bold text-white rounded">
              <span className="absolute top-0 left-0 flex items-center justify-center bg-green-400 rounded">
                <FontAwesomeIcon className="justify-items-start" icon="sign-out-alt" />
              </span>
              <div className="textLogoutMobile">{frontmatter.logoutbutton}</div>
            </LogoutButtonMobile>
          </div> : ''}
        <hr />
        {menuList.map(item => (
          <div key={item.node.frontmatter.templateKey} className={isDarkMode ? "hover:bg-gray-700" : "hover:bg-white"}>
            <Link
              className={`nav-link font-bold items-center columns flex m-5 p-3`}
              to={item.node.fields.slug}
            >
              <IconNav icon={`/${item.node.frontmatter.icon_menu}`}
                width={`${item.node.frontmatter.icon_width}px`}
                height={`${item.node.frontmatter.icon_heigh}px`}
                background={isDarkMode ? theme.dark_theme.background_color.fourth : theme.light_theme.background_color.fourth}
              />
              <div className="textmenu">{frontmatter[menuListPath.find(items => items.key === item.node.frontmatter.templateKey).textVal]}</div>
            </Link>
          </div>
        ))}
        <div className={isDarkMode ? "hover:bg-gray-700" : "hover:bg-white"}>
          <div onClick={() => toggleSideandSupport(true)} className="flex items-center p-3 font-bold cursor-pointer nav-link mb-9">
            <SupportButton className="mt-0 ml-5"
              questionIcon={`/${iconWeb.question}`}
              background={isDarkMode ? theme.dark_theme.background_color.fourth : theme.light_theme.background_color.fourth}
            ></SupportButton>
            <div className={isDarkMode ? "textsupportdark" : "textsupportlight"}
              style={{ color: `${isDarkMode ? theme.dark_theme.font_color.primary : theme.light_theme.font_color.primary}` }}
            >
              {frontmatter.contact_title}
            </div>
          </div>
        </div>
        <hr />
        <div className="flex">
          <ModeWrapper className="flex items-center p-3 m-5">
            <span style={{ color: `${isDarkMode ? theme.dark_theme.font_color.primary : theme.light_theme.font_color.primary}` }}>{frontmatter.navmode}:</span>
            <Toggle />
          </ModeWrapper>
        </div>
      </SideMenu>
    </header>
  )
}

Header.propTypes = {
  siteName: PropTypes.string,
  playUrl: PropTypes.string,
}

Header.defaultProps = {
  siteName: ``,
}

export default Header
